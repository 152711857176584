import React from 'react';
import t from '@jetshop/intl';
import { Currency } from '@jetshop/ui/Price/Currency';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { getSavedAmount, getSavedAmountPercentage } from '../../helpers';
import Styled from './Style';

const ProductDiscountBadge = ({ product, variant, size, ...props }) => {
  const price = useDynamicPrice(product, variant);
  const { hasDiscount } = usePrice(price);
  const savedAmount = hasDiscount ? getSavedAmount(price) : null;
  const savedAmountPercentage = hasDiscount
    ? getSavedAmountPercentage(price)
    : null;

  const percentageDiscount = product?.customFields?.find(
    ({ key }) => key === 'procentrabatt'
  );
  let showPercentageDiscount = false;
  if (percentageDiscount) {
    showPercentageDiscount = percentageDiscount.boolValue;
  }
  const specialDeal = product?.customFields?.find(
    ({ key }) => key === 'specialdeal'
  );
  let showSpecialDeal = false;
  if (specialDeal) {
    showSpecialDeal = true;
  }

  if (!hasDiscount) return null;

  if (showPercentageDiscount) {
    return (
      <Styled.BadgeWrapper>
        <Styled.Badge size={size} specialbadge={showSpecialDeal} {...props}>
          <Styled.Text>
            {t('Save')} {savedAmountPercentage}%
          </Styled.Text>
        </Styled.Badge>
        {showSpecialDeal && (
          <Styled.BadgeSpecial size={size} {...props}>
            <Styled.Text>{specialDeal.stringValue}</Styled.Text>
          </Styled.BadgeSpecial>
        )}
      </Styled.BadgeWrapper>
    );
  }
  return (
    <Styled.BadgeWrapper>
      <Styled.Badge size={size} specialbadge={showSpecialDeal} {...props}>
        <Styled.Text>{t('Save')} </Styled.Text>
        <Currency value={savedAmount} />
      </Styled.Badge>
      {showSpecialDeal && (
        <Styled.BadgeSpecial size={size} {...props}>
          <Styled.Text>{specialDeal.stringValue}</Styled.Text>
        </Styled.BadgeSpecial>
      )}
    </Styled.BadgeWrapper>
  );
};

export default ProductDiscountBadge;
