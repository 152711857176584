import React from 'react';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import Styled from './Style';

const Favourite = ({ product, variant, listId = null, size }) => {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList(listId);

  const toggleWithoutBubble = e => {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber,
      productName: product.name
    });
  };

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <Styled.FavouriteButton
      onClick={toggleWithoutBubble}
      aria-label="Add to favourites"
      size={size}
    >
      {isInList ? <Styled.HeartFilled /> : <Styled.HeartOutlined />}
    </Styled.FavouriteButton>
  );
};

export default Favourite;
