import React from 'react';
import { Link } from 'react-router-dom';
import Styled from './Style';

const CampaignLink = ({ externalLinkUrl, internalLinkUrl, linkLabel }) => {
  if (externalLinkUrl) {
    return (
      <a className={Styled.linkStyles} href={externalLinkUrl}>
        {linkLabel}
      </a>
    );
  }
  if (internalLinkUrl) {
    return (
      <Link className={Styled.linkStyles} to={internalLinkUrl}>
        {linkLabel}
      </Link>
    );
  }
};

const CampaignBar = props => {
  const { externalLinkUrl, internalLinkUrl, linkLabel, text } = props;

  if (!text) return null;

  return (
    <Styled.CampaignBarWrapper>
      <Styled.CampaignBarContent>
        {text && (
          <Styled.CampaignText>{text?.value?.value}</Styled.CampaignText>
        )}
        {linkLabel?.value?.value &&
          (externalLinkUrl?.value?.value || internalLinkUrl?.value?.value) && (
            <CampaignLink
              externalLinkUrl={externalLinkUrl?.value?.value}
              internalLinkUrl={internalLinkUrl?.value?.value}
              linkLabel={linkLabel?.value?.value}
            />
          )}
      </Styled.CampaignBarContent>
    </Styled.CampaignBarWrapper>
  );
};

export default CampaignBar;
