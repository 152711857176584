import { cleanProps } from '../helpers';

const useChildren = props => {
  const propsCleaned = cleanProps(props);
  const { children, ...rest } = propsCleaned;

  const allProps = {
    ...rest
  };

  if (children && children.length > 0) {
    children.forEach(child => {
      if (child?.type) {
        const childProps = cleanProps(child.props);

        if (!(child.type in allProps)) {
          allProps[child.type] = [];
        }

        const propsToPush = {
          ...childProps,
          key: child.key
        };

        allProps[child.type].push(propsToPush);
      }
    });
  }
  return allProps;
};

export default useChildren;
