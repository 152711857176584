import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

const Nav = styled('nav')`
  display: flex;

  ${theme.above.lg} {
    flex: 3;
  }
`;

const CategoryMenu = ({ data, className }) => (
  <Nav className={className}>
    <CategoryMenuContainer queryData={data} />
  </Nav>
);

export default CategoryMenu;
