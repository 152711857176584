//Evobike.se

const helloRetailIds = {
  'Evobike.se': {
    category_page_retargeted: 'k6639bd6d1188df179d76dcbf', //Category page - Retargeted - Box 1
    category_page_top_products: 'k6647347b2180bb4307667d61', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k6647347b2180bb4307667d55', //Front page - New Arrivals
    front_page_popular_sale: 'k6647347b2180bb4307667d67', //Front page - Popular on sale
    front_page_retargeted: 'k6647347b2180bb4307667d5b', //Front page - Retargeted
    front_page_top_purchased: 'k6647347b2180bb4307667d7a', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k6647347b2180bb4307667d74', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k6647347b2180bb4307667d80', //Product page - Products purchased together
    upsell_step: 'k6647347b2180bb4307667d6d' //Upsell step - Products purchased together
  },
  'Evobike.no': {
    category_page_retargeted: 'k666811b0318264647d8b5564', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666811b0318264647d8b5552', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666811b0318264647d8b555e', //Front page - New Arrivals
    front_page_popular_sale: 'k666811b0318264647d8b554b', //Front page - Popular on sale
    front_page_retargeted: 'k666811b0318264647d8b5558', //Front page - Retargeted
    front_page_top_purchased: 'k666811b0318264647d8b5537', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666811b0318264647d8b553d', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666811b0318264647d8b5531', //Product page - Products purchased together
    upsell_step: 'k666811b0318264647d8b5545' //Upsell step - Products purchased together
  },
  'Evobike.fi': {
    category_page_retargeted: 'k666811eb5b4a507d3bb81459', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666811eb5b4a507d3bb81446', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666811eb5b4a507d3bb81453', //Front page - New Arrivals
    front_page_popular_sale: 'k666811eb5b4a507d3bb81440', //Front page - Popular on sale
    front_page_retargeted: 'k666811eb5b4a507d3bb8144c', //Front page - Retargeted
    front_page_top_purchased: 'k666811eb5b4a507d3bb8142d', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666811eb5b4a507d3bb81434', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666811eb5b4a507d3bb81426', //Product page - Products purchased together
    upsell_step: 'k666811eb5b4a507d3bb8143a' //Upsell step - Products purchased together
  },
  'Evobike.dk': {
    category_page_retargeted: 'k666812132a8ec706189e8357', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666812132a8ec706189e8343', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666812132a8ec706189e8351', //Front page - New Arrivals
    front_page_popular_sale: 'k666812132a8ec706189e833a', //Front page - Popular on sale
    front_page_retargeted: 'k666812132a8ec706189e8349', //Front page - Retargeted
    front_page_top_purchased: 'k666812132a8ec706189e8327', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666812132a8ec706189e832d', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666812132a8ec706189e8321', //Product page - Products purchased together
    upsell_step: 'k666812132a8ec706189e8333' //Upsell step - Products purchased together
  },
  'Rull.se': {
    category_page_retargeted: 'k6668124d6cd9c904e6be3968', //Category page - Retargeted - Box 1
    category_page_top_products: 'k6668124d6cd9c904e6be3956', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k6668124d6cd9c904e6be3962', //Front page - New Arrivals
    front_page_popular_sale: 'k6668124d6cd9c904e6be3950', //Front page - Popular on sale
    front_page_retargeted: 'k6668124d6cd9c904e6be395c', //Front page - Retargeted
    front_page_top_purchased: 'k6668124c6cd9c904e6be393e', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k6668124d6cd9c904e6be3944', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k6668124c6cd9c904e6be3938', //Product page - Products purchased together
    upsell_step: 'k6668124d6cd9c904e6be394a' //Upsell step - Products purchased together
  },
  'Ruller.no': {
    category_page_retargeted: 'k66681306af4fb5318333e3a2', //Category page - Retargeted - Box 1
    category_page_top_products: 'k66681306af4fb5318333e38b', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k66681306af4fb5318333e39c', //Front page - New Arrivals
    front_page_popular_sale: 'k66681306af4fb5318333e384', //Front page - Popular on sale
    front_page_retargeted: 'k66681306af4fb5318333e392', //Front page - Retargeted
    front_page_top_purchased: 'k66681306af4fb5318333e371', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k66681306af4fb5318333e377', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k66681306af4fb5318333e369', //Product page - Products purchased together
    upsell_step: 'k66681306af4fb5318333e37e' //Upsell step - Products purchased together
  },
  'Rull.fi': {
    category_page_retargeted: 'k6668135eca417f03c9554817', //Category page - Retargeted - Box 1
    category_page_top_products: 'k6668135eca417f03c955482a', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k6668135eca417f03c955481d', //Front page - New Arrivals
    front_page_popular_sale: 'k6668135eca417f03c9554830', //Front page - Popular on sale
    front_page_retargeted: 'k6668135eca417f03c9554823', //Front page - Retargeted
    front_page_top_purchased: 'k6668135eca417f03c9554843', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k6668135eca417f03c955483d', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k6668135eca417f03c9554849', //Product page - Products purchased together
    upsell_step: 'k6668135eca417f03c9554837' //Upsell step - Products purchased together
  },
  'Rull.dk': {
    category_page_retargeted: 'k666813882a8ec706189ed0ed', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666813882a8ec706189ed0d8', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666813882a8ec706189ed0e7', //Front page - New Arrivals
    front_page_popular_sale: 'k666813882a8ec706189ed0d0', //Front page - Popular on sale
    front_page_retargeted: 'k666813882a8ec706189ed0df', //Front page - Retargeted
    front_page_top_purchased: 'k666813882a8ec706189ed0bb', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666813882a8ec706189ed0c2', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666813882a8ec706189ed0b5', //Product page - Products purchased together
    upsell_step: 'k666813882a8ec706189ed0c9' //Upsell step - Products purchased together
  },
  'Sportig.se': {
    category_page_retargeted: 'k66681410ca417f03c9556cd2', //Category page - Retargeted - Box 1
    category_page_top_products: 'k66681410ca417f03c9556cbd', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k66681410ca417f03c9556ccc', //Front page - New Arrivals
    front_page_popular_sale: 'k66681410ca417f03c9556cb7', //Front page - Popular on sale
    front_page_retargeted: 'k66681410ca417f03c9556cc4', //Front page - Retargeted
    front_page_top_purchased: 'k66681410ca417f03c9556ca2', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k66681410ca417f03c9556caa', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k66681410ca417f03c9556c9c', //Product page - Products purchased together
    upsell_step: 'k66681410ca417f03c9556cb1' //Upsell step - Products purchased together
  },
  'Sportig.no': {
    category_page_retargeted: 'k666814952a8ec706189f08a9', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666814952a8ec706189f0896', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666814952a8ec706189f08a2', //Front page - New Arrivals
    front_page_popular_sale: 'k666814952a8ec706189f0890', //Front page - Popular on sale
    front_page_retargeted: 'k666814952a8ec706189f089c', //Front page - Retargeted
    front_page_top_purchased: 'k666814952a8ec706189f087e', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666814952a8ec706189f0884', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666814952a8ec706189f0878', //Product page - Products purchased together
    upsell_step: 'k666814952a8ec706189f088a' //Upsell step - Products purchased together
  },
  'Sportig.fi': {
    category_page_retargeted: 'k666814bfca417f03c9559143', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666814bfca417f03c9559131', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666814bfca417f03c955913d', //Front page - New Arrivals
    front_page_popular_sale: 'k666814bfca417f03c955912b', //Front page - Popular on sale
    front_page_retargeted: 'k666814bfca417f03c9559137', //Front page - Retargeted
    front_page_top_purchased: 'k666814bfca417f03c9559115', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666814bfca417f03c955911d', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666814bfca417f03c955910f', //Product page - Products purchased together
    upsell_step: 'k666814bfca417f03c9559123' //Upsell step - Products purchased together
  },
  'Sportig.dk': {
    category_page_retargeted: 'k666816346cd9c904e6bf0baa', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816346cd9c904e6bf0b98', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816346cd9c904e6bf0ba4', //Front page - New Arrivals
    front_page_popular_sale: 'k666816346cd9c904e6bf0b91', //Front page - Popular on sale
    front_page_retargeted: 'k666816346cd9c904e6bf0b9e', //Front page - Retargeted
    front_page_top_purchased: 'k666816346cd9c904e6bf0b7b', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816346cd9c904e6bf0b83', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816346cd9c904e6bf0b75', //Product page - Products purchased together
    upsell_step: 'k666816346cd9c904e6bf0b89' //Upsell step - Products purchased together
  },
  'Blimo.se': {
    category_page_retargeted: 'k6668168c5b4a507d3bb90e04', //Category page - Retargeted - Box 1
    category_page_top_products: 'k6668168c5b4a507d3bb90df2', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k6668168c5b4a507d3bb90dfe', //Front page - New Arrivals
    front_page_popular_sale: 'k6668168c5b4a507d3bb90dec', //Front page - Popular on sale
    front_page_retargeted: 'k6668168c5b4a507d3bb90df8', //Front page - Retargeted
    front_page_top_purchased: 'k6668168c5b4a507d3bb90dd8', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k6668168c5b4a507d3bb90dde', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k6668168c5b4a507d3bb90dd2', //Product page - Products purchased together
    upsell_step: 'k6668168c5b4a507d3bb90de6' //Upsell step - Products purchased together
  },
  'Blimo.no': {
    category_page_retargeted: 'k666816a8ca417f03c955f4f4', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816a8ca417f03c955f4e2', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816a8ca417f03c955f4ee', //Front page - New Arrivals
    front_page_popular_sale: 'k666816a8ca417f03c955f4dc', //Front page - Popular on sale
    front_page_retargeted: 'k666816a8ca417f03c955f4e8', //Front page - Retargeted
    front_page_top_purchased: 'k666816a8ca417f03c955f4c9', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816a8ca417f03c955f4cf', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816a8ca417f03c955f4c2', //Product page - Products purchased together
    upsell_step: 'k666816a8ca417f03c955f4d6' //Upsell step - Products purchased together
  },
  'Blimo.fi': {
    category_page_retargeted: 'k666816c35b4a507d3bb9195b', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816c35b4a507d3bb91949', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816c35b4a507d3bb91955', //Front page - New Arrivals
    front_page_popular_sale: 'k666816c35b4a507d3bb91943', //Front page - Popular on sale
    front_page_retargeted: 'k666816c35b4a507d3bb9194f', //Front page - Retargeted
    front_page_top_purchased: 'k666816c35b4a507d3bb9192e', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816c35b4a507d3bb91936', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816c35b4a507d3bb91927', //Product page - Products purchased together
    upsell_step: 'k666816c35b4a507d3bb9193d' //Upsell step - Products purchased together
  },
  'Blimo.dk': {
    category_page_retargeted: 'k666816de2a8ec706189f7e1d', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816de2a8ec706189f7e09', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816de2a8ec706189f7e16', //Front page - New Arrivals
    front_page_popular_sale: 'k666816de2a8ec706189f7e03', //Front page - Popular on sale
    front_page_retargeted: 'k666816de2a8ec706189f7e0f', //Front page - Retargeted
    front_page_top_purchased: 'k666816de2a8ec706189f7df0', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816de2a8ec706189f7df7', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816de2a8ec706189f7dea', //Product page - Products purchased together
    upsell_step: 'k666816de2a8ec706189f7dfd' //Upsell step - Products purchased together
  },
  'Andningslarm.se': {
    category_page_retargeted: 'k666816716cd9c904e6bf179f', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816716cd9c904e6bf178c', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816716cd9c904e6bf1798', //Front page - New Arrivals
    front_page_popular_sale: 'k666816716cd9c904e6bf1785', //Front page - Popular on sale
    front_page_retargeted: 'k666816716cd9c904e6bf1792', //Front page - Retargeted
    front_page_top_purchased: 'k666816716cd9c904e6bf1773', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816716cd9c904e6bf1779', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816716cd9c904e6bf176d', //Product page - Products purchased together
    upsell_step: 'k666816716cd9c904e6bf177f' //Upsell step - Products purchased together
  },
  'Elmoped.se': {
    category_page_retargeted: 'k666816502a8ec706189f61e5', //Category page - Retargeted - Box 1
    category_page_top_products: 'k666816502a8ec706189f61d1', //Category page - Top products in category - Box 1
    front_page_new_arrivals: 'k666816502a8ec706189f61dd', //Front page - New Arrivals
    front_page_popular_sale: 'k666816502a8ec706189f61cb', //Front page - Popular on sale
    front_page_retargeted: 'k666816502a8ec706189f61d7', //Front page - Retargeted
    front_page_top_purchased: 'k666816502a8ec706189f61b7', //Front page - Top/Most purchased products - Box 1
    product_page_alternatives: 'k666816502a8ec706189f61bf', //Product page - Alternatives - PIE
    products_page_purchased_together: 'k666816502a8ec706189f61b0', //Product page - Products purchased together
    upsell_step: 'k666816502a8ec706189f61c5' //Upsell step - Products purchased together
  }
};

export default helloRetailIds;
