import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(56)};

  ${theme.above.lg} {
    flex: 1;
    padding: 0 ${toRem(16)};
    margin-bottom: 0;
  }
  ${theme.above.xl} {
    flex: 2;
    padding: 0 ${toRem(48)} 0 ${toRem(80)};
  }
`;

const Description = styled.p`
  padding-top: ${toRem(16)};
  padding-bottom: ${toRem(20)};
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
`;

const ReadMoreLink = styled(Link)`
  color: ${theme.colorScheme.white};
  font-weight: ${theme.fontWeights.semibold};
  font-size: ${toRem(14)};
  line-height: ${toRem(26)};
  :hover {
    color: ${theme.colorScheme.primary};
  }
`;

const style = { Wrapper, Description, ReadMoreLink };

export default style;
