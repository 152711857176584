import React, { useContext } from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from '@apollo/react-hooks';
import footerQuery from '../../../gql/queries/FooterQuery.gql';
import FooterContent from './FooterContent/FooterContent';
import TrustpilotBlock from './TrustpilotBlock/TrustpilotBlock';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const getFooterCategoryId = () => {
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;
  switch (envChannelId) {
    case '1':
      return 153;
    case '5':
      return 378;
    case '9':
      return 376;
    case '13':
      return 380;
    case '17':
      return 382;
    case '18':
      return 384;
    default:
      return 153;
  }
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);
  var footerCategoryIdTmp;
  if (selectedChannel.id === 3) {
    //Evobike.fi separate footer for Trustpilot
    footerCategoryIdTmp = 476;
  } else if (selectedChannel.id === 7) {
    //Rull.fi separate footer for Trustpilot
    footerCategoryIdTmp = 475;
  } else if (selectedChannel.id === 11) {
    //Blimo.fi separate footer for Trustpilot
    footerCategoryIdTmp = 477;
  } else if (selectedChannel.id === 15) {
    //Sportig.fi separate footer for Trustpilot
    footerCategoryIdTmp = 478;
  } else {
    footerCategoryIdTmp = getFooterCategoryId();
  }
  const footerCategoryId = footerCategoryIdTmp;
  const { data } = useQuery(footerQuery, {
    variables: { footerCategoryId: footerCategoryId }
  });

  const components = {
    BADGE: 'badge',
    FLAGS: 'flags',
    LINKGROUPONEITEM: 'linkGroupOneItem',
    LINKGROUPTWOITEM: 'linkGroupTwoItem',
    PAYMENTICON: 'paymentIcon',
    TRUSTPILOTBLOCK: TrustpilotBlock,
    SITEFOOTER: FooterContent
  };

  return (
    <>
      <ContentRenderer
        items={data?.category?.data?.items ?? []}
        components={components}
      />
    </>
  );
};

export default Footer;
