import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { toRem } from '../../../helpers';
import { theme } from '../../Theme';
import BrandLogo from '../BrandLogo';

const LogoWrapper = styled(Link)`
  display: block;
  width: 6.75rem;
  line-height: 1;
  svg {
    max-height: ${toRem(28)};
  }

  ${theme.below.md} {
    flex: 3;
    max-width: 7.5rem;
  }
  ${theme.above.lg} {
    margin-right: ${toRem(60)};
  }
`;

const HeaderLogo = () => (
  <LogoWrapper to="/">
    <BrandLogo placement="header" />
  </LogoWrapper>
);

export default HeaderLogo;
