import { styled } from 'linaria/react';
import { toRem } from '../../helpers';
import { ReactComponent as ChevronDown } from '../../assets/svg/ChevronDown.svg';
import { ReactComponent as Check } from '../../assets/svg/Check.svg';
import { theme } from '../Theme';

const VariantSelectorContainer = styled('div')`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5em;
  > * {
    flex-grow: 1;
  }
`;

const DropdownVariantSelectContainer = styled('div')``;

const ChevronIcon = styled(ChevronDown)`
  transform: rotate(0deg);
  transition: transform 0.25s;
`;

const CheckIcon = styled(Check)`
  width: ${toRem(12)};
  height: ${toRem(8)};
`;

const Label = styled('label')`
  font-size: ${toRem(12)};
  font-weight: ${theme.fontWeights.medium};
  padding-bottom: ${toRem(8)};
`;

const style = {
  VariantSelectorContainer,
  DropdownVariantSelectContainer,
  CheckIcon,
  ChevronIcon,
  Label
};

export default style;
