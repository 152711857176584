import React from 'react';
import { css, cx } from 'linaria';

import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';

import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import { useNotification } from '@jetshop/core/components/Notifications';

import { ReactComponent as CartIcon } from '../../assets/svg/Cart.svg';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import ProductGrid from '../ProductGrid';

import addMultipleToCartMutation from '../../gql/mutations/addMultipleToCart.gql';
import cartQuery from '../../gql/queries/cartQuery.gql';

export function Favourites() {
  const listId = null;

  const { products, loading } = useProductListItems(listId);
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <CartIcon />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('There are no products in your saved items list.')}</p>
      </MaxWidth>
    );
  }

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
        <h1>{t('Saved items')}</h1>
        <p>{t('Loading your saved items…')}</p>
      </MaxWidth>
    );
  }

  return (
    <MaxWidth className={cx(container, loading ? 'loading' : null)}>
      <h1>{t('Saved items')}</h1>
      <ProductGrid listId={listId} products={products} />

      <div className="add-clear">
        <Button
          hideArrow
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {validItemCount > 0
            ? mutationLoading
              ? t('One moment…')
              : t(
                  '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                  { productCount: validItemCount }
                )
            : t('No valid items to add to cart')}
        </Button>
        <ClearList listId={listId}>{t('Clear list')}</ClearList>
      </div>
    </MaxWidth>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children, listId }) {
  const { clear } = useProductList(listId);
  return (
    <Button hideArrow secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const container = css`
  margin-top: 2em;
  margin-bottom: 2em;
  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin-bottom: 1em;
    font-weight: 600;
  }
  .add-clear {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colorScheme.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;
