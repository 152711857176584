import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { toRem } from '../../helpers';

const FullWidth = styled('div')`
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: ${({ maxwidth }) =>
    maxwidth ? `calc(${toRem(1440)} - 1.5rem)` : 'none'};

  ${theme.above.sm} {
    max-width: ${({ maxwidth }) =>
      maxwidth ? `calc(${toRem(1440)} - 4rem)` : 'none'};
  }
`;

export default FullWidth;
