import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';

const Title = styled.h3`
  font-size: ${toRem(18)};
  line-height: ${toRem(28)};
  color: ${theme.colorScheme.white};
  position: relative;
  padding-bottom: ${toRem(12)};

  ::after {
    content: '';
    width: ${toRem(22)};
    height: ${toRem(3)};
    background-color: ${theme.colorScheme.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export default Title;
