import { styled } from 'linaria/react';
import { toRem } from '../../../../helpers';
import { theme } from '../../../Theme';
import MaxWidth from '../../MaxWidth';

const Wrapper = styled('section')`
  position: relative;
  background: ${theme.colorScheme.darkgrey};
  font-family: 'Poppins';
  color: ${theme.colorScheme.white};
`;

const WrapFooterNav = styled(MaxWidth)`
  padding: ${toRem(42)} ${toRem(20)};

  ${theme.above.lg} {
    flex-direction: row;
    flex: 1 1 auto;
    padding: ${toRem(60)};
  }
`;

const LogoAndContactWrapper = styled.div`
  ${theme.above.lg} {
    padding-right: ${toRem(48)};
  }
`;

const style = {
  Wrapper,
  WrapFooterNav,
  LogoAndContactWrapper
};

export default style;
