import React from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import AnimateHeight from 'react-animate-height';
import Styled from './Style';
import { List, ListItem } from '../../../ui/List';
import Heading from '../../../ui/Heading';
import { useIntl } from '@jetshop/intl';

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  const t = useIntl();
  const hasSubCategories = activeCategory?.subcategories.find(
    ({ subcategories }) => subcategories?.length
  );
  return (
    <Styled.SubMenuContainer className={pose === 'open' && 'isOpen'}>
      <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
        <Styled.InnerWrapper className={hasSubCategories && 'hasSubCategories'}>
          {activeCategory &&
            activeCategory.subcategories.map((cat, index) => (
              <Styled.CategoryLinkWrapper key={cat.id}>
                <CategoryLink category={cat} onClick={closeNav}>
                  {hasSubCategories ? (
                    <Heading level={4} asLevel={5}>
                      {cat.name}
                    </Heading>
                  ) : (
                    cat.name
                  )}
                </CategoryLink>
                {cat.subcategories.length ? (
                  <List limit={5}>
                    {cat.subcategories.map(category => (
                      <ListItem key={category.id}>
                        <CategoryLink category={category} onClick={closeNav}>
                          {category.name}
                        </CategoryLink>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  hasSubCategories && (
                    <List>
                      <ListItem>
                        <CategoryLink category={cat} onClick={closeNav}>
                          {t('Show all')}
                        </CategoryLink>
                      </ListItem>
                    </List>
                  )
                )}
              </Styled.CategoryLinkWrapper>
            ))}
        </Styled.InnerWrapper>
      </AnimateHeight>
    </Styled.SubMenuContainer>
  );
};

export default SubMenuWrapper;
