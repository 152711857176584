import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { ReactComponent as Email } from '../../../../assets/svg/Email.svg';
import { ReactComponent as Phone } from '../../../../assets/svg/Phone.svg';
import { toRem } from '../../../../helpers';

const ContactWrapper = styled.div`
  margin-bottom: ${toRem(50)};
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${toRem(10)};
`;

const Link = styled.a`
  color: ${theme.colorScheme.white};
  text-decoration: ${props => (props.type === 'email' ? 'underline' : 'none')};
  margin-left: ${toRem(8)};
  :hover {
    color: ${theme.colorScheme.primary};
  }
`;

const EmailIcon = styled(Email)`
  path {
    fill: ${theme.colorScheme.primary};
  }
`;

const PhoneIcon = styled(Phone)`
  path {
    fill: ${theme.colorScheme.primary};
  }
`;
const style = { ContactWrapper, RowWrapper, Link, EmailIcon, PhoneIcon };

export default style;
