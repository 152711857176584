import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import MaxWidth from '../MaxWidth';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';

const Container = styled('header')`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: ${theme.colorScheme.black};
  color: ${theme.colorScheme.white};

  .header-button {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    svg {
      width: 18px;
      height: 20px;
    }

    .badge-svg-wrapper {
      height: 20px;
      position: relative;
      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        font-size: 0.5rem;
      }
    }
  }
`;

const HeaderContainer = styled(MaxWidth)`
  padding: 0 0.7rem;
  ${theme.above.md} {
    padding: 0 ${toRem(36)};
  }
  ${theme.above.md} {
    order: 1;
  }
  ${theme.below.md} {
    order: 2;
    padding-top: 0.5rem;
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${toRem(16)};

  ${theme.above.md} {
    margin-bottom: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: center;
  margin-right: ${toRem(18)};
`;

const Hamburger = styled('button')`
  width: ${toRem(30)};
  height: ${toRem(22)};
  position: relative;
  background: transparent;
  margin-right: ${toRem(18)};
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;

  span {
    height: ${toRem(4)};
    width: 100%;
    display: block;
    position: absolute;
    border-radius: ${toRem(4)};
    left: 0;
    background-color: ${theme.colorScheme.primary};
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    :nth-child(1) {
      top: ${props => (props.open ? toRem(18) : 0)};
      width: ${props => (props.open ? '0%' : '100%')};
      left: ${props => (props.open ? '50%' : '0')};
    }
    :nth-child(2),
    :nth-child(3) {
      top: ${toRem(9)};
    }
    :nth-child(2) {
      transform: ${props => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
    }
    :nth-child(3) {
      transform: ${props => (props.open ? 'rotate(-45deg)' : 'rotate(0deg)')};
    }
    :nth-child(4) {
      top: ${toRem(18)};
      width: ${props => (props.open ? '0%' : '100%')};
      left: ${props => (props.open ? '50%' : '0')};
    }
  }
`;

const MyPagesLink = styled(Link)`
  margin-right: ${toRem(24)};
  :hover {
    svg {
      path {
        stroke: ${theme.colorScheme.primary};
      }
    }
  }
`;

const BackgroundBlur = styled('div')`
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 101;
  transition: transform 0.3s ease-in-out;
`;

const HamburgerLogoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Usp = styled('div')`
  ${theme.above.md} {
    order: 2;
  }
  ${theme.below.md} {
    order: 1;
    transition: max-height 0.4s;
    max-height: ${props => (props.isShrunk ? '0px' : '34px')};
    overflow: hidden;
  }
`;

const style = {
  Container,
  HeaderContainer,
  HeaderItemsContainer,
  IconContainer,
  MenuButton,
  MyPagesLink,
  Hamburger,
  BackgroundBlur,
  HamburgerLogoWrapper,
  Usp
};

export default style;
