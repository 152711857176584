import React from 'react';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';

const Wrapper = styled.span`
  white-space: nowrap;
  text-decoration: ${props => props.decoration || 'none'};
`;

const countryFormats = {
  sv: { currency: 'SEK', format: price => `${price} kr` },
  no: { currency: 'NOK', format: price => `${price} ,-` },
  nb: { currency: 'NOK', format: price => `${price} ,-` },
  dk: { currency: 'DKK', format: price => `${price} ,-` },
  da: { currency: 'DKK', format: price => `${price} ,-` },
  fi: { currency: 'EUR', format: price => `${price} €` }
};
export default function Price({
  price,
  countryCode = 'sv',
  children,
  className
}) {
  const t = useIntl();
  if (isNaN(price)) {
    return children || null;
  }
  const [country] = countryCode.split('-');
  const formattedPrice = new Intl.NumberFormat(country).format(price);
  return (
    <Wrapper
      className={className}
      title={`${formattedPrice} ${countryFormats[country]?.currency || ''} ${t(
        'inc. VAT'
      )}`}
    >
      {countryFormats[country]?.format(formattedPrice) ||
        children ||
        formattedPrice}
    </Wrapper>
  );
}
