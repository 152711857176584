import React, { useState } from 'react';
import { SearchField } from '@jetshop/ui/Search';
import AutocompleteQuery from '../../../../gql/queries/AutocompleteQuery.gql';
import { useIntl } from '@jetshop/intl';
import Styled from './Style';
import { ReactComponent as SearchIcon } from '../../../../assets/svg/Search.svg';
import { ReactComponent as CloseSearch } from '../../../../assets/svg/CloseSmall.svg';

const MobileSearch = () => {
  const t = useIntl();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  return (
    <Styled.PosedSearchBarContainer key={'searchBarPosed'}>
      <Styled.InnerSearchBar
        onFocus={() => {
          setSearchActive(!searchActive);
        }}
        onBlur={() => {
          setSearchActive(!searchActive);
        }}
      >
        <SearchField
          autocompleteQuery={AutocompleteQuery}
          onCancel={() => setSearchOpen(false)}
          className={Styled.searchFieldStyles}
          placeholder={`${t('Search')}...`}
          searchOpen={searchOpen}
        />
      </Styled.InnerSearchBar>

      {searchActive ? (
        <CloseSearch onClick={() => setSearchActive(false)} />
      ) : (
        <SearchIcon />
      )}
    </Styled.PosedSearchBarContainer>
  );
};

export default MobileSearch;
