import React from 'react';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import { css } from 'linaria';
import { ReactComponent as HeartSVG } from '../../assets/svg/Heart.svg';
import Badge from '../ui/Badge';
import { styled } from 'linaria/react';
import { toRem } from '../../helpers';
import { theme } from '../Theme';

const svgStyle = css`
  vertical-align: unset;
  path {
    fill: ${theme.colorScheme.white};
  }
`;
const StyledLink = styled(Link)`
  margin-right: ${toRem(24)};
  :hover {
    svg {
      path {
        fill: ${theme.colorScheme.primary};
      }
    }
  }
`;
export function FavouriteCount({ className, listId, showLabel = false }) {
  const { count } = useProductList(listId);

  return (
    <StyledLink to="/favourites" className={className}>
      <div className="badge-svg-wrapper">
        {count > 0 && <Badge text={count} />}
        <HeartSVG className={svgStyle} />
      </div>
      {showLabel && <span>{t('Favourites')}</span>}
    </StyledLink>
  );
}
