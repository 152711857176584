import { useIntl } from '@jetshop/intl';
import DropdownMenu from '../ui/DropdownMenu/DropdownMenu';
import React, { useState, useEffect } from 'react';

const DropdownVariantSelect = ({
  size,
  option,
  variantHandler,
  doNotDisable
}) => {
  const [isValid, setIsValid] = useState(true);
  const { getSelectedValue, validateSelection, selectValue } = variantHandler;
  const t = useIntl();
  const selectedValue = getSelectedValue(option);

  // If selected value is out of stock, try to find better option
  useEffect(() => {
    if (
      ['invalid', 'outOfStock'].includes(
        validateSelection(selectedValue, option)
      )
    ) {
      const firstValidValue = option.values.find(
        value =>
          !['invalid', 'outOfStock'].includes(validateSelection(value, option))
      );
      if (firstValidValue) {
        selectValue(firstValidValue, option);
      }
    }
  }, []);

  useEffect(() => {
    const state = validateSelection(selectedValue, option);
    const isValid = !['invalid'].includes(state);
    setIsValid(isValid);
  }, [selectedValue]);

  return (
    <DropdownMenu
      size={size}
      id={option.name}
      label={option.name}
      placeholder={t.rich('Select {option}', { option: option.name })}
      options={option.values.map(value => {
        const validation = validateSelection(value, option);
        return {
          text: value,
          value,
          disabled: !doNotDisable && validation === 'invalid',
          invalid: validation === 'outOfStock' // greyed out but enabled
        };
      })}
      isValid={isValid}
      value={selectedValue}
      onSelect={value => {
        selectValue(value, option);
      }}
    />
  );
};

export default DropdownVariantSelect;
