import { css } from 'linaria';

import SSPWoff from './source-sans-pro-v11-latin-regular.woff';
import SSPWoff2 from './source-sans-pro-v11-latin-regular.woff2';

import SSPWoffBold from './source-sans-pro-v11-latin-700.woff';
import SSPWoff2Bold from './source-sans-pro-v11-latin-700.woff2';

import SSPWoffSemiBold from './source-sans-pro-v13-latin-600.woff';
import SSPWoff2SemiBold from './source-sans-pro-v13-latin-600.woff2';

import RubikRegularWoff from './rubik-v14-latin-regular.woff';
import RubikRegularWoff2 from './rubik-v14-latin-regular.woff2';
import RubikRegularTTF from './rubik-v14-latin-regular.ttf';

import RubikMediumWoff from './rubik-v14-latin-500.woff';
import RubikMediumWoff2 from './rubik-v14-latin-500.woff2';
import RubikMediumTTF from './rubik-v14-latin-500.ttf';

import RubikSemiBoldWoff from './rubik-v14-latin-600.woff';
import RubikSemiBoldWoff2 from './rubik-v14-latin-600.woff2';
import RubikSemiBoldTTF from './rubik-v14-latin-600.ttf';

import RubikBoldWoff from './rubik-v14-latin-700.woff';
import RubikBoldWoff2 from './rubik-v14-latin-700.woff2';
import RubikBoldTTF from './rubik-v14-latin-700.ttf';

import PoppinsRegularTTF from './Poppins-Regular.ttf';
import PoppinsMediumTTF from './Poppins-Medium.ttf';
import PoppinsSemiBoldTTF from './Poppins-SemiBold.ttf';
import PoppinsBoldTTF from './Poppins-Bold.ttf';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font 400 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Regular'), url(${RubikRegularWoff2}) format('woff2'),
          url(${RubikRegularWoff}) format('woff'),
          url(${RubikRegularTTF}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      /* Medium font 500 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Medium'), url(${RubikMediumWoff2}) format('woff2'),
          url(${RubikMediumWoff}) format('woff'),
          url(${RubikMediumTTF}) format('truetype');
        font-style: normal;
        font-weight: 500;
        font-display: swap;
      }
      /* Semi-bold font 600 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Semi-bold'),
          url(${RubikSemiBoldWoff2}) format('woff2'),
          url(${RubikSemiBoldWoff}) format('woff'),
          url(${RubikSemiBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 600;
        font-display: swap;
      }
      /* Bold font 700 */
      @font-face {
        font-family: 'Rubik';
        src: local('Rubik Bold'), url(${RubikBoldWoff2}) format('woff2'),
          url(${RubikBoldWoff}) format('woff'),
          url(${RubikBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 700;
        font-display: swap;
      }

      /* Regular font 400 */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Regular'),
          url(${PoppinsRegularTTF}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      /* Medium font 500 */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Medium'),
          url(${PoppinsMediumTTF}) format('truetype');
        font-style: normal;
        font-weight: 500;
        font-display: swap;
      }
      /* Semi-bold font 600 */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Semi-bold'),
          url(${PoppinsSemiBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 600;
        font-display: swap;
      }
      /* Bold font 700 */
      @font-face {
        font-family: 'Poppins';
        src: local('Poppins Bold'), url(${PoppinsBoldTTF}) format('truetype');
        font-style: normal;
        font-weight: 700;
        font-display: swap;
      }

      /* Regular font */
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
          url(${SSPWoff2}) format('woff2'), url(${SSPWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
          url(${SSPWoff2Bold}) format('woff2'),
          url(${SSPWoffBold}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
          url(${SSPWoff2SemiBold}) format('woff2'),
          url(${SSPWoffSemiBold}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      &.source-sans-pro-ready body {
        font-family: 'Source Sans Pro', sans-serif;
        line-height: 1;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
