const colors = {
  white: '#FFFFFF',
  black: '#000000',
  darkGrey: '#1F1F1F',
  mediumGrey: '#646464',
  lightGrey: '#D2D2D2',
  superLightGrey: '#F6F6F6',
  red: '#FF0000',
  evobikered: '#e40338',
  transparent: 'transparent',
  evoBikePrimary: '#03e483',
  rullPrimary: '#63C1DD',
  sportigPrimary: '#fff600',
  blimoPrimary: '#b40607',
  andningslarmPrimary: '#0D718D',
  elmopedPrimary: '#3DCC68'
};

const colorSchemeDefault = {
  white: colors.white,
  black: colors.black,
  darkgrey: colors.darkGrey,
  mediumgrey: colors.mediumGrey,
  lightgrey: colors.lightGrey,
  superlightgrey: colors.superLightGrey,
  red: colors.red,
  transparent: colors.transparent
};

const colorSchemeEvoBike = {
  ...colorSchemeDefault,
  primary: colors.evoBikePrimary,
  red: colors.evobikered
};

const colorSchemeRull = {
  ...colorSchemeDefault,
  primary: colors.rullPrimary
};

const colorSchemeBlimo = {
  ...colorSchemeDefault,
  primary: colors.blimoPrimary
};

const colorSchemeSportig = {
  ...colorSchemeDefault,
  primary: colors.sportigPrimary
};

const colorSchemeElmoped = {
  ...colorSchemeDefault,
  primary: colors.elmopedPrimary
};

const colorSchemeAndningslarm = {
  ...colorSchemeDefault,
  primary: colors.andningslarmPrimary
};

const storeChannelIds = {
  evobike: 1,
  rull: 5,
  blimo: 9,
  sportig: 13,
  elmoped: 17,
  andningslarm: 18
};

const colorSchemes = {
  evobike: colorSchemeEvoBike,
  rull: colorSchemeRull,
  blimo: colorSchemeBlimo,
  sportig: colorSchemeSportig,
  elmoped: colorSchemeElmoped,
  andningslarm: colorSchemeAndningslarm
};
const appTitle = {
  evobike: 'EvoBike',
  rull: 'Rull',
  blimo: 'Blimo',
  sportig: 'Sportig',
  elmoped: 'Elmoped',
  andningslarm: 'Andningslarm'
};
const imageFallback = {
  evobike: '/pub_images/small/evobike-logga.png',
  rull: '/pub_images/small/rull-logga.png',
  blimo: '/pub_images/small/blimo-logga.png',
  sportig: '/pub_images/small/sportig-logga.png',
  elmoped: '/pub_images/small/elmoped-logga.png',
  andningslarm: '/pub_images/small/andningslarm-logga.png'
};
const favIcons = {
  evobike: '/favicon-evobike.ico?v=2',
  rull: '/favicon-rull.ico?v=2',
  blimo: '/favicon-blimo.ico',
  sportig: '/favicon-sportig.ico',
  elmoped: '/favicon-elmoped.ico',
  andningslarm: '/favicon-andningslarm.ico'
};

const fontSizeBaseValue = 16; //pixels
const channelId = Number(process.env.REACT_APP_CHANNEL_ID || 1);
const channelKey = Object.keys(storeChannelIds).find(
  key => Number(storeChannelIds[key]) === channelId
);

module.exports = {
  default: {
    title: appTitle[channelKey],
    colorScheme: {
      ...colorSchemes[channelKey]
    },
    favIcon: favIcons[channelKey],
    imageFallback: imageFallback[channelKey],
    colors: {
      ...colors
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    widths: {
      maxWidth: 1440,
      textContent: 1024
    },
    breakpoints: {
      xs: '20rem', //320px
      sm: '40rem', //640px
      md: '50rem', //800px
      lg: '64rem', //1024px
      xl: '80rem', //1280px
      xxl: '100rem' //1600px
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    gap: {
      none: 0,
      small: '8px',
      medium: '16px',
      large: '24px',
      xlarge: '32px'
    },
    fonts: {
      primary: 'Poppins'
    }
  },
  storeChannelIds,
  fontSizeBaseValue
};
