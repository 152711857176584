import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';
import { usePrice } from '@jetshop/core/hooks/usePrice';
import usePriceHistory from './usePriceHistory';

const useDynamicPriceWithHistory = (product, variant) => {
  const { price, previousPrice } = useDynamicPrice(product, variant);
  const history = usePriceHistory(product, variant);
  const { hasDiscount, formattedPricing, currencyLocale } = usePrice({
    price,
    previousPrice
  });
  const [
    { price: latestPrice } = {
      price: previousPrice
    }
  ] = history?.previousPrice || [];
  return {
    price,
    previousPrice,
    latestPrice,
    history,
    hasDiscount,
    formattedPricing,
    currencyLocale
  };
};

export default useDynamicPriceWithHistory;
