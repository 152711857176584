import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { ReactComponent as Person } from '../../../assets/svg/Person.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import CategoryMenu from './CategoryMenu/CategoryMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import DesktopSearch from './Search/DesktopSearch';
import MobileSearch from './Search/MobileSearch';
import homeCategoriesQuery from '../../../gql/queries/HomeCategoriesQuery.gql';
import HeaderQuery from '../../../gql/queries/HeaderQuery.gql';
import InfoBar from '../../InfoBar/InfoBar';
import CampaignBar from '../../CampaignBar/CampaignBar';
import HeaderLogo from './HeaderLogo';
import Styled from './Style';

const coverStyles = {
  background: 'rgba(255, 255, 255, 0.1)'
};

const TopHeader = ({ data }) => {
  const { routes } = useShopConfig();

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderItemsContainer>
        <Below breakpoint="md">
          {matches => (
            <Styled.HamburgerLogoWrapper>
              {matches && (
                <DrawerTrigger
                  className="below-md"
                  preventOverflow={true}
                  id="menu-drawer"
                  coverStyles={coverStyles}
                >
                  {drawer => (
                    <>
                      <Styled.Hamburger
                        onClick={
                          drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                        }
                        open={drawer.isOpen}
                      >
                        <span />
                        <span />
                        <span />
                        <span />
                      </Styled.Hamburger>
                      {drawer.isOpen && <Styled.BackgroundBlur />}
                    </>
                  )}
                </DrawerTrigger>
              )}
              <HeaderLogo />
            </Styled.HamburgerLogoWrapper>
          )}
        </Below>

        <Above breakpoint="md">
          {matches => (
            <>
              {matches && <CategoryMenu className="above-md" data={data} />}
              <Styled.IconContainer>
                {matches && <DesktopSearch />}
                {matches && (
                  <Styled.MyPagesLink
                    className="header-button"
                    to={routes.myPages.path}
                  >
                    <Person />
                  </Styled.MyPagesLink>
                )}
                <FavouriteCount className="header-button" />
                <CartButton className="header-button" />
              </Styled.IconContainer>
            </>
          )}
        </Above>
        <CartFlyout />
      </Styled.HeaderItemsContainer>
      <Below breakpoint="md">
        {matches =>
          matches && (
            <>
              <MobileMenu className="below-md" data={data} />
              <MobileSearch className="below-md" />
            </>
          )
        }
      </Below>
    </Styled.HeaderContainer>
  );
};

const topBannerComponents = {
  CAMPAIGNBAR: CampaignBar,
  TEXTITEM: 'textItem',
  LINKITEM: 'linkItem'
};

const bottomBannerComponents = {
  INFOBAR: InfoBar,
  TEXTITEM: 'textItem'
};

const getHeaderCategoryId = () => {
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;

  switch (envChannelId) {
    case '1':
      return 152;
    case '5':
      return 377;
    case '9':
      return 375;
    case '13':
      return 379;
    case '17':
      return 381;
    case '18':
      return 383;
    default:
      return 152;
  }
};

const Header = () => {
  const [isShrunk, setShrunk] = useState(false);

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const headerCategoryId = getHeaderCategoryId();

  const { data } = useQuery(HeaderQuery, {
    variables: { headerCategoryId: headerCategoryId }
  });

  useEffect(() => {
    const handler = () => {
      setShrunk(isShrunk => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 30 ||
            document.documentElement.scrollTop > 30)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 1 &&
          document.documentElement.scrollTop < 1
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <>
      <Styled.Container>
        <ContentRenderer
          items={data?.category?.data?.items ?? []}
          components={topBannerComponents}
        />
        <TopHeader categories={result} data={result.data} />
        <Styled.Usp isShrunk={isShrunk}>
          <ContentRenderer
            items={data?.category?.data?.items ?? []}
            components={bottomBannerComponents}
          />
        </Styled.Usp>
      </Styled.Container>
    </>
  );
};

export default Header;
