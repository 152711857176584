import React from 'react';
import Title from '../SectionTitle';
import Styled from './Style';

const About = props => {
  const { description, linkLabel, linkUrl, title } = props;

  return (
    <Styled.Wrapper>
      {title && <Title>{title}</Title>}
      {description && <Styled.Description>{description}</Styled.Description>}
      {linkLabel && linkUrl && (
        <Styled.ReadMoreLink to={linkUrl}>{linkLabel}</Styled.ReadMoreLink>
      )}
    </Styled.Wrapper>
  );
};

export default About;
