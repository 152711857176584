import * as translations from '../translations';
import channelsQuery from './gql/queries/ChannelsQuery.gql';
import homeCategoriesQuery from './gql/queries/HomeCategoriesQuery.gql';
import routeQuery from './gql/queries/RouteQuery.gql';
import { LoadableWindowedCategoryPage } from './pages/CategoryPage/CategoryPage';
import { theme } from './components/Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import ProductPage from './pages/ProductPage/ProductPage.loadable';
import ContentPage from './pages/ContentPage/ContentPage.loadable';
import { addwishTracker } from '@jetshop/flight-addwish';

const getHappyRetailId = () => {
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;
  switch (envChannelId) {
    case '1':
      return 'a2a22c11-bc5f-4ce7-ac2a-0b4be1e060cd'; //Evobike.se
    case '2':
      return '4380a3bf-9c60-4170-8ba0-1ed7c9125668'; //.no
    case '3':
      return '425775c7-3799-4085-b622-e7ff54c8ff07'; //.fi
    case '4':
      return 'e20c7a97-da2d-4c16-b062-f9a2cc7fbf6f'; //.dk
    case '5':
      return '59627b40-605e-4ac5-b1bd-1d3a08bc85a3'; //Rull.se
    case '6':
      return '6e36ae68-bb62-4c1a-b548-3e09ffa93073'; //.no
    case '7':
      return '38ddb457-505b-4bf6-b654-087528635a58'; //.fi
    case '8':
      return '51fcb4d4-02f8-4113-bac3-d1bd1f086afa'; //.dk
    case '9':
      return '18024495-b5ae-45aa-9ca4-53679e8b2e5f'; //Blimo.se
    case '10':
      return '89285285-61ef-47da-a79b-7ba790ce5b27'; //.no
    case '11':
      return '5078955b-ed59-4f3b-8922-da6084672561'; //.fi
    case '12':
      return 'c9068374-166f-4416-a4a5-438e3a40c432'; //.dk
    case '13':
      return '696a4672-8b13-4b72-9cd2-45e53bad6658'; //Sportig.se
    case '14':
      return 'e8ce445b-9589-42b1-9949-d431c3f3d64d'; //.no
    case '15':
      return '69148140-a129-4245-ade6-568c6a9739c4'; //.fi
    case '16':
      return '5dbbb25d-81c2-4533-96cd-fda33da7f0a4'; //.dk
    case '17':
      return '3b13f622-064e-4d48-ac78-8738e3d5f975'; //Elmoped.se
    case '18':
      return '071539cd-f7c5-4db3-a0e0-591d6ec9ca34'; //Andningslarm.se
    default:
      return 'a2a22c11-bc5f-4ce7-ac2a-0b4be1e060cd'; //Evobike.se
  }
};
const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'nordbutiker',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: false,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [addwishTracker(getHappyRetailId())],
  serverTrackers: [
    createFacebookTracker({
      pixelID: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  }
};

export default config;
