import { styled } from 'linaria/react';
import { toRem } from '../../../../helpers';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.colorScheme.black};
  padding: ${toRem(44)} 0;
`;

const InfoText = styled.p`
  color: ${theme.colorScheme.white};
  margin-bottom: ${toRem(20)};
`;
const IconWrapper = styled.div`
  color: ${theme.colorScheme.white};
`;

const style = { Wrapper, InfoText, IconWrapper };

export default style;
