import React, { useEffect, useRef } from 'react';
import { styled } from 'linaria/react';

const TrustpilotWrapper = styled('div')`
  width: auto;
  height: auto;
  margin: 30px;
`;

const LoadTrustpilotScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src =
    '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
  script.async = true;
  document.head.appendChild(script);
};

const TrustpilotBlock = ({ trustpilotItem }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      const [TrustpilotElement] =
        ref.current?.getElementsByClassName('trustpilot-widget') || [];
      TrustpilotElement &&
        window.Trustpilot.loadFromElement(TrustpilotElement, true);
    } else {
      LoadTrustpilotScript();
    }
  }, []);

  const rawHTMLValue = trustpilotItem?.value?.value;
  if (!rawHTMLValue) return null;

  return (
    <>
      <TrustpilotWrapper
        ref={ref}
        dangerouslySetInnerHTML={{ __html: rawHTMLValue }}
      />
    </>
  );
};

export default TrustpilotBlock;
