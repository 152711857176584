import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const LinkGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(56)};
  &:last-child {
    margin-bottom: 0;
  }
  ${theme.above.lg} {
    flex: 1;
    padding: 0 ${toRem(16)};
    margin-bottom: 0;
  }
`;

const LinksWrapper = styled.div`
  padding-top: ${toRem(16)};
  display: flex;
  flex-direction: column;
`;

const linkStyles = css`
  margin-bottom: ${toRem(12)};
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colorScheme.white};

  text-decoration: none;
  &:last-child {
    margin-bottom: 0;
  }
  :hover {
    color: ${theme.colorScheme.primary};
  }
`;

const externalLinkStyles = css`
  text-decoration: none;
`;
const style = {
  LinkGroupWrapper,
  LinksWrapper,
  linkStyles,
  externalLinkStyles
};

export default style;
