import React from 'react';
import useChildren from '../../hooks/useChildren';
import InfoBarItem from './InfoBarItem';
import Styled from './Style';

const InfoBar = props => {
  const cleanedProps = useChildren(props);
  const textItems = cleanedProps['textItem'];

  if (!textItems) return null;

  return (
    <Styled.InfoBarWrapper>
      <Styled.InfoBarContent>
        {textItems.map(item => {
          return <InfoBarItem key={item.key} item={item} />;
        })}
      </Styled.InfoBarContent>
    </Styled.InfoBarWrapper>
  );
};

export default InfoBar;
