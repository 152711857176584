import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ReactComponent as HeartWithOutline } from '../../assets/svg/Heart.svg';
import { ReactComponent as FilledHeart } from '../../assets/svg/HeartFilled.svg';

const FavouriteButton = styled('button')`
  display: flex;
  background: transparent;
  color: inherit;

  svg {
    height: ${props => (props.size === 'lg' ? '27px' : '18px')};
    width: ${props => (props.size === 'lg' ? '30px' : '20px')};
  }
`;

const HeartOutlined = styled(HeartWithOutline)`
  :hover {
    color: ${theme.colorScheme.primary};
  }
`;

const HeartFilled = styled(FilledHeart)`
  color: ${theme.colorScheme.primary};
`;

const style = { FavouriteButton, HeartOutlined, HeartFilled };

export default style;
