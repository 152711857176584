import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import { theme } from '../../Theme';
import { toRem } from '../../../helpers';

const BadgesWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  padding-right: ${toRem(28)};

  img {
    margin-left: ${toRem(16)};
  }
  ${theme.above.lg} {
    padding-right: ${toRem(40)};
  }
`;

const Badges = ({ badges }) => {
  if (!badges) return null;

  return (
    <MaxWidth>
      <BadgesWrapper>
        {badges.map(badge => {
          return <img key={badge.key} src={badge.image} alt={badge.alt} />;
        })}
      </BadgesWrapper>
    </MaxWidth>
  );
};

export default Badges;
