import React, { Component } from 'react';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { cx } from 'linaria';
import t from '@jetshop/intl';
import Styled from './Style';

export default class DrawerMenu extends Component {
  renderRootCategory = (categories, subLevel) => {
    return (
      <Accordion single>
        {({ openIndexes, handleClick, AccordionContent }) =>
          categories.map((cat, index) => {
            let classNames = [];
            // Give categories with children a chevron
            cat.subcategories?.length && classNames.push('with-chevron');
            // Add 'open' class when accordion is opened
            const open = openIndexes.includes(index);
            open && classNames.push('open');
            subLevel && classNames.push('subLevel');

            return (
              <Styled.RootCategory key={cat.id}>
                <>
                  <Styled.CategoryLink
                    category={cat}
                    onClick={e =>
                      this.handleClick(
                        e,
                        cat.subcategories?.length,
                        index,
                        handleClick
                      )
                    }
                    className={
                      classNames.length > 0 ? classNames.join(' ') : null
                    }
                  >
                    {cat.name}
                    <Styled.Chevron open={open} />
                  </Styled.CategoryLink>
                </>
                <AccordionContent isOpen={open}>
                  {this.renderSubCategories(cat)}
                </AccordionContent>
              </Styled.RootCategory>
            );
          })
        }
      </Accordion>
    );
  };

  renderSubCategories = category => {
    const { subcategories } = category;
    const hasSubCategories = subcategories.find(
      ({ subcategories }) => subcategories?.length
    );
    if (!subcategories || !subcategories.length) return null;
    const { closeMenu } = this.props;
    return (
      <Styled.SubCategories>
        <Styled.SubCategory>
          <Styled.SubCategoryLink
            exact
            category={category}
            onClick={() => closeMenu()}
            className={cx(
              Styled.allInCategoryLink,
              hasSubCategories && 'root-level'
            )}
          >
            {t('View all in {category}', {
              category: category.name.toLowerCase()
            })}
          </Styled.SubCategoryLink>
        </Styled.SubCategory>
        {hasSubCategories
          ? this.renderRootCategory(subcategories, true)
          : subcategories.map(cat => {
              return (
                <Styled.SubCategory key={cat.id}>
                  <Styled.SubCategoryLink
                    category={cat}
                    onClick={() => closeMenu()}
                  />
                </Styled.SubCategory>
              );
            })}
      </Styled.SubCategories>
    );
  };

  handleClick = (e, hasChildren, index, handleAccordionClick) => {
    // If there are subcategories, prevent following the link
    const { closeMenu } = this.props;
    if (hasChildren) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      closeMenu();
    }
    handleAccordionClick(index);
  };

  render() {
    const { categories } = this.props;

    if (categories && categories.length > 0) {
      return (
        <Styled.CategoryTree className="root">
          {this.renderRootCategory(categories)}
        </Styled.CategoryTree>
      );
    } else return null;
  }
}
