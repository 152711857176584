import React from 'react';
import DropdownVariantSelect from './DropDownVariantSelect';
import Styled from './Style';

const VariantSelector = ({
  size,
  product,
  variantHandler,
  showValidation = true,
  disableOutOfStock
}) => {
  return (
    <Styled.VariantSelectorContainer>
      {product.variants?.options?.map((option, index) => {
        let showValidationMessage = false;
        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        return (
          <DropdownVariantSelect
            disableOutOfStock={disableOutOfStock}
            size={size}
            key={option.name}
            option={option}
            variantHandler={variantHandler}
            showValidationMessage={showValidationMessage}
          />
        );
      })}
    </Styled.VariantSelectorContainer>
  );
};

export default VariantSelector;
