import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.9rem;
  width: 0.9rem;
  border: 0;
  border-radius: 50%;
  line-height: 0.9rem;
  background-color: ${theme.colorScheme.primary};
  color: ${theme.colorScheme.black};
  overflow: hidden;
  padding-top: 2px;
`;

const Badge = ({ text }) => {
  return <Wrapper className="badge">{text}</Wrapper>;
};

export default Badge;
