import React from 'react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Title from '../SectionTitle';
import Styled from './Style';

const LinkGroupLink = ({ link }) => {
  const { externalLinkUrl, internalLinkUrl, linkTitle } = link;

  if (externalLinkUrl) {
    return (
      <a
        className={cx(Styled.linkStyles, Styled.externalLinkStyles)}
        href={externalLinkUrl}
        target="_blank"
        rel="noreferrer"
      >
        {linkTitle}
      </a>
    );
  }
  if (internalLinkUrl) {
    return (
      <Link className={Styled.linkStyles} to={internalLinkUrl}>
        {linkTitle}
      </Link>
    );
  }
  return null;
};

const LinkGroup = props => {
  const { title, links } = props;

  if (!links) return null;

  return (
    <Styled.LinkGroupWrapper>
      {title && <Title>{title}</Title>}
      <Styled.LinksWrapper>
        {links.map(link => {
          return <LinkGroupLink key={link.key} link={link} />;
        })}
      </Styled.LinksWrapper>
    </Styled.LinkGroupWrapper>
  );
};

export default LinkGroup;
