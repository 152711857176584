import React from 'react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import SubMenuWrapper from './SubMenuWrapper';
import Styled from './Style';
import { toRem } from '../../../../helpers';

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Styled.MainCategoryWrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem
                    key={cat.id}
                    cat={cat}
                    props={props}
                    numMenuItems={queryData.categories.length}
                  />
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Styled.MainCategoryWrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props, numMenuItems }) => {
  const fontSize = `clamp(${toRem(
    9
  )}, calc((min(100vw - 718px, 740px) / ${numMenuItems}) / 7), ${toRem(14)})`;
  return (
    <Styled.MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => props.setActiveCategory(cat)}
      numMenuItems={numMenuItems}
      fontSize={fontSize}
    >
      <CategoryLink
        // When following a category link, close the menu
        onClick={props.clearActiveCategories}
        category={cat}
      >
        {cat.name}
      </CategoryLink>
    </Styled.MainMenuItem>
  );
};

export default CategoryMenuContainer;
