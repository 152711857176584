import HelloRetailQuery from './HelloRetailQuery.gql';
import { useQuery } from '@apollo/react-hooks';

const useArticleInfo = articleNumber => {
  const articleNumberArr = [articleNumber];
  const { loading, error, data } = useQuery(HelloRetailQuery, {
    variables: { articleNumbers: articleNumberArr },
    skip: !articleNumber // Skip the query if articleNumber is not provided
  });
  return { loading, error, data };
};

export default useArticleInfo;
