import { styled } from 'linaria/react';
import { css } from 'linaria';
import { toRem } from '../../helpers';

const styles = `
  font-style: normal;
  font-weight: 700;
  line-height: 1.48em;
  font-size: 1rem;
  letter-spacing: 0.01em;
  margin-bottom: 0.75rem;
`;
export const baseStyles = css`
  ${styles}
`;

export const smallCaps = css`
  ${styles};
  font-weight: 600;
  text-transform: uppercase;
  font-variant: small-caps;
`;
export const SmallCaps = styled.h1`
  ${styles};
  font-weight: 600;
  text-transform: uppercase;
  font-variant: small-caps;
`;

export const heading1 = css`
  ${styles};
  font-size: ${toRem(56)};
  line-height: 1.02em;
`;
export const heading2 = css`
  ${styles};
  font-size: ${toRem(32)};
`;
export const heading3 = css`
  ${styles};
  font-size: ${toRem(28)};
`;
export const heading4 = css`
  ${styles};
  font-size: ${toRem(24)};
`;
export const heading5 = css`
  ${styles};
  font-size: 1rem;
`;
