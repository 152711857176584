import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { ProductCard } from './ProductCardCheckout';
import { CarouselCheckout, CarouselItemCheckout } from './ui/CarouselCheckout';

export function isBeforeScroll(index) {
  return index < 4;
}

export function ProductCarouselCheckout({
  products,
  listName,
  loading,
  className,
  readMore,
  dismiss,
  notificationId,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <CarouselCheckout data-testid="product-carousel">
      {products.map((product, index) => {
        return (
          <CarouselItemCheckout key={index + ':' + product.articleNumber}>
            <ProductCard
              imageSizes={[1, 2 / 3, 1 / 2, 1 / 3, 1 / 3]}
              as="section"
              product={product}
              style={{ opacity: loading ? 0.5 : 1 }}
              list={listName}
              loadImageEagerly={isBeforeScroll(index)}
              hideExcerpt="true"
              dismiss={dismiss}
              notificationId={notificationId}
              {...rest}
            />
          </CarouselItemCheckout>
        );
      })}
    </CarouselCheckout>
  );
}

export default ProductCarouselCheckout;
