import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { ProductCard } from './ProductCardCheckout';
import { theme } from './Theme';
import { Grid, GridCell } from './ui/Grid';
import Link from './ui/Link';

export const priceStyle = css`
  [data-flight-price] {
    display: flex;
  }
  .new-price {
    color: #eb0000;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .old-price {
    color: ${theme.colorScheme.mediumgrey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};

  .product-card {
    line-height: 19px;
    min-width: 0;

    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }
`;

export function isAboveFold(index) {
  return index < 4;
}

export function ProductGridCheckout({
  products,
  listName,
  loading,
  className,
  readMore,
  widths = {},
  dismiss,
  notificationId,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  return !products?.length ? null : (
    <Grid
      data-testid="product-grids"
      className={cx('product-grid', className, priceStyle)}
    >
      {products.map((product, index) => {
        return (
          <GridCell
            key={index + ':' + product.articleNumber}
            {...{
              xs: widths.xs || 1 / 2,
              sm: widths.sm,
              md: widths.sm || 1 / 3,
              lg: widths.lg || 1 / 4,
              xl: widths.xl || 1 / 5
            }}
          >
            <ProductCard
              as="section"
              product={product}
              style={{ opacity: loading ? 0.5 : 1 }}
              list={listName}
              loadImageEagerly={isAboveFold(index)}
              hideExcerpt="true"
              dismiss={dismiss}
              notificationId={notificationId}
              {...rest}
            ></ProductCard>
          </GridCell>
        );
      })}
      {readMore && readMore.to !== '0' ? (
        <GridCell
          align="center"
          {...{
            xs: widths.xs || 1 / 2,
            sm: widths.sm,
            md: widths.sm || 1 / 3,
            lg: widths.lg || 1 / 4,
            xl: widths.xl || 1 / 5
          }}
        >
          <Link plain internalLink={readMore.to}>
            {readMore.text}
          </Link>
        </GridCell>
      ) : null}
    </Grid>
  );
}

export default ProductGridCheckout;
