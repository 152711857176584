import React from 'react';
import { cx } from 'linaria';
import Styled from './Style';

const Drawer = ({
  right = false,
  size = '320px',
  isOpen = false,
  children
}) => {
  const initialPosition = right // this is for server side rendering since popmotion doesn't support it
    ? {
        transform: `translateX(${isOpen ? '0px' : size})`,
        right: 0
      }
    : {
        transform: `translateX(-${isOpen ? '0px' : size})`,
        left: 0
      };
  return (
    <Styled.DrawerContainer
      className={cx(isOpen ? 'open' : 'closed', right ? 'right' : 'left')}
      style={{ width: `${size}`, ...initialPosition }}
    >
      {children}
    </Styled.DrawerContainer>
  );
};

export default Drawer;
