import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';

const DrawerContainer = styled.div`
  height: auto;
  max-width: 100%;
  background: transparent;
  position: absolute;
  top: 2.5rem;
  z-index: 99999;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  transition: transform 0.3s ease-in-out;
`;

const Scroll = styled('div')`
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  background-color: #fff;
  height: calc(100vh - 75px);
  padding-bottom: ${toRem(50)};
`;

const SecondaryMenuItem = styled.li`
  list-style: none;
  display: block;
  padding: ${toRem(20)} ${toRem(24)};
  color: ${theme.colorScheme.black};
  a {
    font-size: ${toRem(18)};
    line-height: ${toRem(25)};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colorScheme.black};
    text-decoration: none;
    :active {
      text-decoration: none;
    }
    :visited {
      color: black;
    }
  }

  button {
    background: none;
    color: #666;
  }
`;

const categoryLinkStyles = css`
  font-size: ${toRem(18)};
  line-height: ${toRem(25)};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colorScheme.black};
`;

const style = {
  DrawerContainer,
  Scroll,
  SecondaryMenuItem,
  categoryLinkStyles
};

export default style;
