import React from 'react';
import Styled from './Style';

const InfoBarItem = ({ item }) => (
  <Styled.InfoBarItemWrapper>
    <Styled.UspCheck />
    <Styled.Text>{item.text}</Styled.Text>
  </Styled.InfoBarItemWrapper>
);

export default InfoBarItem;
