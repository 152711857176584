import { styled } from 'linaria/react';
import { toRem } from '../../helpers';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 var(--layout-gutter, 1rem);
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: ${toRem(1440)};
`;

export default MaxWidth;
