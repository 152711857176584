import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';
import MaxWidth from '../../MaxWidth';

const MainCategoryWrapper = styled('div')`
  position: relative;

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 1rem 0;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: inline-block;
    color: ${theme.colorScheme.white};
    font-size: ${props => (props.fontSize ? props.fontSize : toRem(12))};
    font-weight: ${theme.fontWeights.medium};
    text-decoration: none;
    position: relative;
    line-height: 1;

    &.active {
      color: #fff;

      ::after {
        content: '';
        display: block;
        background-color: ${theme.colorScheme.primary};
        height: ${toRem(4)};
        width: 100%;
        bottom: 0;

        position: absolute;
        animation: greenUnderline 0.3s;
      }
    }

    &:hover {
      ::after {
        content: '';
        display: block;
        background-color: ${theme.colorScheme.primary};
        height: ${toRem(4)};
        width: 100%;
        bottom: 0;
        position: absolute;
        animation: greenUnderline 0.3s;
      }
    }
  }

  &:last-child {
    a {
      margin-right: 0;
    }
  }

  @keyframes greenUnderline {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`;

const InnerWrapper = styled(MaxWidth)`
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: ${toRem(20)};
  overflow: auto;
  max-height: 300px;
  &.hasSubCategories {
    flex-direction: row;
    gap: 2rem;
    max-height: none;
    & > * {
      flex: 0 0 calc(33% - 1.25rem);
      margin-bottom: 0;
    }
  }
`;

const SubMenuContainer = styled('div')`
  position: absolute;
  min-width: 800px;
  width: 100%;
  max-width: 80vw;
  left: 0;
  background: ${theme.colorScheme.white};
  border: 1px solid ${theme.colorScheme.lightgrey};
  color: ${theme.colorScheme.black};
  border-top: none;
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;
  display: none;
  &.isOpen {
    display: block;
  }
  ${theme.below.lg} {
    left: 70%;
    transform: translateX(-50%);
  }
  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`;
const CategoryLinkWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: ${toRem(12)};
  line-height: ${toRem(20)};

  a {
    display: block;
    font-size: ${toRem(14)};
    color: ${theme.colorScheme.black};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
  li a {
    padding: 0.25em 0;
  }
`;

const style = {
  MainCategoryWrapper,
  MainMenuItem,
  InnerWrapper,
  CategoryLinkWrapper,
  SubMenuContainer
};

export default style;
