import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';
import { toRem } from '../../helpers';
import MaxWidth from '../Layout/MaxWidth';

const CampaignBarWrapper = styled('div')`
  background-color: ${theme.colorScheme.primary};
  color: ${theme.colorScheme.black};
  font-family: 'Poppins';
`;

const CampaignBarContent = styled(MaxWidth)`
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${toRem(8)} 0;

  ${theme.below.sm} {
    padding: ${toRem(8)};
  }
`;

const CampaignText = styled('p')`
  font-size: ${toRem(12)};
  text-align: center;
  padding: 0 ${toRem(4)};
`;

const linkStyles = css`
  font-size: ${toRem(12)};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colorScheme.black};
  padding: 0 ${toRem(4)};
`;

const style = {
  CampaignBarWrapper,
  CampaignBarContent,
  CampaignText,
  linkStyles
};

export default style;
