import React from 'react';
import Styled from './Style';

const PaymentIcons = ({ infoText, icons }) => {
  if (!icons && !infoText) return null;

  return (
    <Styled.Wrapper>
      {infoText && <Styled.InfoText>{infoText}</Styled.InfoText>}
      {icons && (
        <Styled.IconWrapper>
          {icons.map(icon => {
            return <img key={icon.key} src={icon.image} alt={icon.alt} />;
          })}
        </Styled.IconWrapper>
      )}
    </Styled.Wrapper>
  );
};
export default PaymentIcons;
