import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { heading1, heading2, heading3, heading4, baseStyles } from './Headings';

const Heading = ({
  className,
  level = 2,
  asLevel,
  underline,
  children,
  noMargin
}) => {
  const HeadingTag = styled(`h${level || 1}`)`
    position: relative;

    ::after {
      content: ${() => (underline ? "''" : 'none')};
      position: absolute;
      bottom: 0em;
      left: 0;
      background: ${theme.colorScheme.primary};
      height: 3px;
      width: 30%;
      max-width: 3rem;
    }
  `;
  const styleLevel = Number(asLevel || level || 1);
  const text =
    typeof children === 'string' && children.replace(/<[^>]*?>/g, '');
  return (
    <HeadingTag
      className={cx(
        className,
        (styleLevel === 1 && heading1) ||
          (styleLevel === 2 && heading2) ||
          (styleLevel === 3 && heading3) ||
          (styleLevel === 4 && heading4) ||
          baseStyles
      )}
    >
      {text ? <span dangerouslySetInnerHTML={{ __html: text }} /> : children}
    </HeadingTag>
  );
};
export default Heading;
