import React from 'react';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';
import { toRem } from '../../helpers';
import { ReactComponent as Arrow } from '../../assets/svg/Arrow.svg';

export const baseStyles = `
  display: flex;
  gap: ${toRem(10)};
  align-items: center;
  justify-content: center;
  background-color: ${theme.colorScheme.primary};
  border: 2px solid ${theme.colorScheme.primary};
  color: ${theme.colorScheme.black};
  font-size: ${toRem(14)};
  font-weight: ${theme.fontWeights.medium};
  padding: 0.5rem 1rem;
  break-word: avoid;
  text-decoration: none;

  svg {
    position: relative;
    fill: ${theme.colorScheme.black};
    height: 1em;
    width: 1em;
    path {
      fill: ${theme.colorScheme.black};
    }
    &.arrow {
      position: relative;
      left: 0;
      transition: left 0.25s;
    }
  }
  ${theme.above.md} {
    max-width: 100%;
  }

  &:disabled {
    border: 1px solid #dedede;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${theme.colorScheme.black};
    border: 2px solid ${theme.colorScheme.black};
    color: ${theme.colorScheme.white};
    svg {
      fill: ${theme.colorScheme.white};
      path {
        fill: ${theme.colorScheme.white};
      }
      &.arrow {
        left: 5px;
      }
    }
  }
  &.reversed {
    background-color: ${theme.colorScheme.black};
    border: 2px solid ${theme.colorScheme.black};
    color: ${theme.colorScheme.white};
    svg {
      fill: ${theme.colorScheme.white};
      path {
        fill: ${theme.colorScheme.white};
      }
    }
    &:hover:not(:disabled) {
      background-color: ${theme.colorScheme.primary};
      border: 2px solid ${theme.colorScheme.primary};
      color: ${theme.colorScheme.black};
      svg {
        fill: ${theme.colorScheme.black};
        path {
          fill: ${theme.colorScheme.black};
        }
      }
    }
  }
  &.secondary {
    background-color: ${theme.colorScheme.transparent};
    border: 2px solid ${theme.colorScheme.black};
    color: ${theme.colorScheme.black};

    &:hover:not(:disabled) {
      background-color: ${theme.colorScheme.black};
      border: 2px solid ${theme.colorScheme.black};
      color: ${theme.colorScheme.white};
    }
  }
  &.rounded {
    border-radius: ${toRem(100)};
  }
  &.plain,
  &.plain:hover,
  &.plain:hover:not(:disabled) {
    background: none;
    border-radius: none;
    color: inherit;
    padding: 0;
    border: none;
    width: auto;
    svg {
      fill: currentColor;
      path {
        fill: currentColor;
      }
    }
  }
  &.plain:hover,
  &.plain:hover:not(:disabled) {
    span {
      text-decoration: underline;
    }
  }
  
  @keyframes arrowHover {
    from {
      left: 0px;
    }
    to {
      left: 5px;
    }
  }
`;

const Button = css`
  ${baseStyles};
`;

export const TrendLink = styled(Link)`
  ${baseStyles};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  children,
  hideArrow,
  href,
  rounded = true,
  reversed,
  plain,
  ...props
}) => {
  const isInternalLink = href?.match(/^\//);
  const TagName = href
    ? isInternalLink
      ? Link
      : 'a' // Use NavLink for internal routes
    : 'button';

  const attributes = {
    ...props,
    ...(href ? (isInternalLink ? { to: href } : { href }) : {})
  };
  if (href) {
  }

  return (
    <TagName
      {...attributes}
      className={cx(
        Button,
        props.className,
        secondary && 'secondary',
        rounded && 'rounded',
        reversed && 'reversed',
        plain && 'plain'
      )}
    >
      {loading ? (
        <>
          <span>{loadingText}</span>
          <StyledSpinner />
        </>
      ) : (
        <>
          {children}
          {!hideArrow && <Arrow className="arrow" />}
        </>
      )}
    </TagName>
  );
};

export default ButtonWithLoading;
