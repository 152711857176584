import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ReactComponent as AndningslarmLogo } from '../../assets/logos/Storelogo_Andningslarm_white.svg';
import { ReactComponent as BlimoLogo } from '../../assets/logos/Storelogo_Blimo_white.svg';
import { ReactComponent as ElmopedLogo } from '../../assets/logos/Storelogo_Elmoped_white.svg';
import { ReactComponent as EvoBikeLogo } from '../../assets/logos/Storelogo_Evobike_white_logotype_text.svg';
import { ReactComponent as RullLogo } from '../../assets/logos/Storelogo_rull_white_logo.svg';
import { ReactComponent as RullerLogo } from '../../assets/logos/Storelogo_ruller_white_logo.svg';
import { ReactComponent as SportigLogo } from '../../assets/logos/Storelogo_Sportig_white.svg';
import { toRem } from '../../helpers';
import { theme } from '../Theme';

const LogoPlaceHolderHeader = styled.div`
  width: 7.5rem;
  height: 1.25rem;
  background-color: ${theme.colorScheme.black};
`;

const LogoPlaceHolderFooter = styled.div`
  width: ${toRem(90)};
  height: ${toRem(65)};
  background-color: ${theme.colorScheme.black};
`;

const getBrandLogo = (envChannelId, languageChannelId, placement) => {
  switch (envChannelId) {
    case '1':
      return <EvoBikeLogo />;
    case '5':
      if (languageChannelId === 6) {
        return <RullerLogo />;
      }
      return <RullLogo />;
    case '9':
      return <BlimoLogo />;
    case '13':
      return <SportigLogo />;
    case '17':
      return <ElmopedLogo />;
    case '18':
      return <AndningslarmLogo />;
    default:
      if (placement === 'header') {
        return <LogoPlaceHolderHeader />;
      }
      return <LogoPlaceHolderFooter />;
  }
};
const BrandLogo = placement => {
  const { selectedChannel } = useContext(ChannelContext);
  const languageChannelId = selectedChannel.id;
  const envChannelId = process.env.REACT_APP_CHANNEL_ID;

  return getBrandLogo(envChannelId, languageChannelId, placement);
};

export default BrandLogo;
