import React from 'react';
import MobileCategories from '../Categories/MobileCategories';
import t from '@jetshop/intl';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Link } from 'react-router-dom';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import Drawer from './Drawer';
import Styled from './Style';

export default function MobileMenu({ data }) {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();

  if (!(data && data.categories)) return null;

  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen}>
          <Styled.Scroll>
            <MobileCategories
              categories={data.categories}
              closeMenu={drawer.hideTarget}
            />
            <Styled.SecondaryMenuItem>
              <Link
                onClick={drawer.hideTarget}
                to={routes.myPages.path}
                className={Styled.categoryLinkStyles}
              >
                {loggedIn ? t('My Pages') : t('Log in')}
              </Link>
            </Styled.SecondaryMenuItem>
          </Styled.Scroll>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
