import React, { useState } from 'react';
import { SearchField } from '@jetshop/ui/Search';
import { useIntl } from '@jetshop/intl';
import Styled from './Style';
import AutocompleteQuery from '../../../../gql/queries/AutocompleteQuery.gql';

const DesktopSearch = () => {
  const [searchOpen, setSearchOpen] = useState(true);
  const t = useIntl();

  return (
    <Styled.Wrapper>
      {searchOpen && (
        <Styled.PosedSearchBarContainer key={'searchBarPosed'}>
          <Styled.InnerSearchBar>
            <SearchField
              autocompleteQuery={AutocompleteQuery}
              onCancel={() => setSearchOpen(true)}
              className={Styled.searchFieldStyles}
              placeholder={`${t('Search')}...`}
            />
          </Styled.InnerSearchBar>
        </Styled.PosedSearchBarContainer>
      )}
    </Styled.Wrapper>
  );
};

export default DesktopSearch;
