import React from 'react';
import { css } from 'linaria';
import { Notifications as Ntf } from '@jetshop/core/components/Notifications';
import { theme } from '../Theme';

export function Notifications() {
  return <Ntf className={notificationsStyles} />;
}

const closeAfter = 1500;

const notificationsStyles = css`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.below.sm} {
    align-items: flex-end;
  }

  [data-flight-notification] {
    width: 1016px;
    background: white;
    position: relative;
    will-change: max-height opacity transform;
    margin-top: 1em;
    transition: opacity ${closeAfter / 2}ms ease-out,
      transform ${closeAfter / 8}ms ease-in-out,
      max-height ${closeAfter / 4}ms ease-in-out;
    overflow: hidden;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

    ${theme.below.lg} {
      width: 800px;
    }
    ${theme.below.md} {
      width: 375px;
    }
    ${theme.below.sm} {
      width: 100%;
      height: calc(100vh - 75px);
    }
  }

  [data-flight-notification='summoned'],
  [data-flight-notification='exiting'] {
    transform: translateX(calc(100% + 1em));
  }
  [data-flight-notification='entering'] {
    transform: translateX(0);
  }
  [data-flight-notification='exiting'] {
    opacity: 0;
    max-height: 0;
  }

  [data-flight-notification-inner] {
    padding: 1em;
    padding-right: 2em;
    min-width: 10em;
    height: 100%;
  }

  [data-flight-notification-dismiss] {
    background: transparent;
    color: black;
    position: absolute;
    right: 1em;
    top: 0.5em;
    svg {
      stroke: currentColor;
    }
    :hover {
      svg {
        stroke: ${theme.colorScheme.primary};
      }
    }
  }

  /* The 'type' passed when triggering the notification can be used for styling */

  [data-flight-notification-type='add-to-cart'] {
    [data-flight-notification-inner] {
      padding: 0;
    }
  }
`;
