import React from 'react';
import Styled from './Style';

const Contact = ({ email, phone }) => {
  if (!email && !phone) return null;

  return (
    <Styled.ContactWrapper>
      {email && (
        <Styled.RowWrapper>
          <Styled.EmailIcon />
          <Styled.Link type="email" href={`mailto:${email}`}>
            {email}
          </Styled.Link>
        </Styled.RowWrapper>
      )}
      {phone && (
        <Styled.RowWrapper>
          <Styled.PhoneIcon />
          <Styled.Link href={`tel:${phone.replace(/[\s-]/g, '')}`}>
            {phone}
          </Styled.Link>
        </Styled.RowWrapper>
      )}
    </Styled.ContactWrapper>
  );
};

export default Contact;
