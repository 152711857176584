import Link from '@jetshop/ui/CategoryLink';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../../../Theme';
import { toRem } from '../../../../helpers';
import { ReactComponent as ChevronDown } from '../../../../assets/svg/ChevronDown.svg';

const CategoryTree = styled('ul')`
  display: flex;
  flex-direction: column;
  list-style: none;

  flex: 1 0 auto;

  a,
  a:hover {
    text-decoration: none;
  }

  &.root {
    border-top: 1px solid #e7e4e4;
  }
`;

const SubCategories = styled(CategoryTree)`
  padding: ${toRem(20)} ${toRem(24)};
  border-bottom: 1px solid #e7e4e4;
`;

const CategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.colorScheme.black};
  text-decoration: none;
  padding: ${toRem(20)} ${toRem(24)};
  font-size: ${toRem(18)};
  font-weight: ${theme.fontWeights.medium};
  svg {
    display: none;
    margin-right: 0px;
  }
  &.subLevel {
    font-size: ${toRem(16)};
    padding: 0.75em 0;
  }
  &.with-chevron {
    justify-content: space-between;
    border-bottom: 1px solid #e7e4e4;
    svg {
      display: block;
      margin-left: ${toRem(14)};
    }
  }

  position: relative;

  ul li &.active,
  ul li ul li &.active {
    font-weight: ${theme.fontWeights.bold};
  }
`;

const SubCategoryLink = styled(CategoryLink)`
  font-size: ${toRem(16)};
  line-height: ${toRem(23)};
  font-weight: ${theme.fontWeights.regular};
  padding: 0;
  border-bottom: 0;
`;

const SubCategory = styled('li')`
  padding: ${toRem(12)} 0;

  :first-child {
    padding-top: 0;
  }
  :last-child {
    padding-bottom: 0;
  }
`;

const RootCategory = styled('li')`
  display: flex;
  flex-direction: column;
`;

const Chevron = styled(ChevronDown)`
  transform: ${props => (props.open ? `rotate(180deg)` : `rotate(0)`)};
`;
const allInCategoryLink = css`
  padding: ${toRem(20)} ${toRem(24)};
  &.root-level {
    font-weight: ${theme.fontWeights.medium};
  }
`;

const style = {
  CategoryLink,
  CategoryTree,
  SubCategories,
  SubCategoryLink,
  SubCategory,
  RootCategory,
  Chevron,
  allInCategoryLink
};

export default style;
