import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const checkChannelCountry = selectedChannel => {
  const countries = { isSE: false, isDK: false, isNO: false, isFI: false };
  switch (selectedChannel.id) {
    case 1:
    case 5:
    case 9:
    case 13:
      return { ...countries, isSE: true };
    default:
      return countries;
  }
};

const useCheckChannelCountry = () => {
  const { selectedChannel } = useContext(ChannelContext);
  return checkChannelCountry(selectedChannel);
};

export default useCheckChannelCountry;
